import { createMemoryHistory, createRouter, createWebHistory } from 'vue-router'

import { useAuthStore } from '@/stores/auth.js'

import PanelSidebar from '@/components/panel/PanelSidebar.vue'
import Header from '@/components/teams/Header.vue'
import LeftSidebar from '@/components/teams/LeftSidebar.vue'
import Auth from '@/layouts/auth.vue'
import Dashboard from '@/layouts/dashboard.vue'
import Default from '@/layouts/default.vue'
import Empty from '@/layouts/empty.vue'
import isBrowser from '@/utils/is-browser.js'

let routes = [
  {
    path: '/',
    component: () => import('@/pages/catalog/index.vue'),
    name: 'index',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/catalog/:id',
    component: () => import('@/pages/catalog/[id].vue'),
    name: 'catalog-id',
    meta: {
      layout: Default,
    },
  },
  {
    path: '/login',
    component: () => import('@/pages/login.vue'),
    name: 'login',
    meta: {
      layout: Auth,
      title: 'Авторизация',
      guest: true,
    },
  },
  {
    path: '/auth/ssa',
    component: () => import('@/pages/ssa.vue'),
    name: 'auth.ssa',
    meta: {
      layout: Auth,
      title: 'Авторизация',
      guest: true,
    },
  },
  {
    path: '/magic',
    component: () => import('@/pages/magic.vue'),
    name: 'magic',
    meta: {
      layout: Empty,
      guest: true,
    },
  },
  {
    path: '/test',
    component: () => import('@/pages/test.vue'),
    name: 'test',
    meta: {
      layout: Empty,
      dev: true,
    },
  },
  {
    path: '/profile/',
    meta: {
      layout: Default,
      auth: true,
    },
    children: [
      {
        path: 'settings',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-settings',
        meta: {
          title: 'Настройки профиля',
        },
      },
      {
        path: 'favorites',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-favorites',
      },
      {
        path: 'works',
        component: () => import('@/pages/catalog/index.vue'),
        name: 'profile-works',
      },
    ],
  },
  {
    path: '/workspace',
    meta: {
      layout: Default,
      auth: true,
      title: 'Рабочее пространство',
      private: true,
    },
    name: 'workspace-index',
    component: () => import('@/pages/workspace/index.vue'),
  },
  {
    path: '/panel',
    meta: {
      layout: Dashboard,
      auth: true,
      title: 'Административная панель',
      private: true,
    },
    name: 'panel.index',
    components: {
      default: () => import('@/pages/panel/index.vue'),
      Header,
      LeftSidebar: PanelSidebar,
    },
    children: [
      // {
      //   path: '',
      //   label: 'Главная',
      //   component: () => import('@/pages/panel/sub-pages/home.vue'),
      //   name: 'panel.home',
      // },
      // {
      //   path: 'users',
      //   label: 'Пользователи',
      //   component: () => import('@/pages/panel/sub-pages/users/index.vue'),
      //   name: 'panel.users',
      // },
      // {
      //   path: 'users/:id',
      //   component: () => import('@/pages/panel/sub-pages/users/[id].vue'),
      //   name: 'panel.users.id',
      // },
      {
        path: '',
        label: 'Конкурсы',
        component: () => import('@/pages/panel/sub-pages/competitions.vue'),
        name: 'panel.competitions',
      },
      {
        path: 'settings',
        label: 'Настройки',
        component: () => import('@/pages/panel/sub-pages/settings.vue'),
        name: 'panel.settings',
      },
    ],
  },
  {
    path: '/workspace/:team',
    meta: {
      layout: Dashboard,
      auth: true,
      title: 'Рабочее пространство',
      private: true,
    },
    name: 'workspace-id',
    component: () => import('@/pages/workspace/[id]/index.vue'),
    components: {
      default: () => import('@/pages/workspace/[id]/index.vue'),
      LeftSidebar,
      Header,
    },
    children: [
      {
        path: 'general',
        component: () => import('@/pages/workspace/[page]/general.vue'),
        name: 'workspace-general',
      },
      {
        path: 'works-form',
        component: () => import('@/pages/workspace/[page]/works-form.vue'),
        name: 'workspace-works-form',
      },
      {
        path: 'reviews-form',
        component: () => import('@/pages/workspace/[page]/reviews-form.vue'),
        name: 'workspace-reviews-form',
      },
      {
        path: 'reviews',
        component: () => import('@/pages/workspace/[page]/reviews/index.vue'),
        name: 'workspace-reviews',
      },
      {
        path: 'reviews/:id',
        component: () => import('@/pages/workspace/[page]/reviews/[id].vue'),
        name: 'workspace-reviews-id',
      },
      {
        path: 'users',
        component: () => import('@/pages/workspace/[page]/users.vue'),
        name: 'workspace-users',
      },
      {
        path: 'competition-works',
        component: () =>
          import('@/pages/workspace/[page]/competition-works/index.vue'),
        name: 'workspace-competition-works',
      },
      {
        path: 'competition-works/:id',
        component: () =>
          import('@/pages/workspace/[page]/competition-works/[id].vue'),
        name: 'workspace-competition-works-id',
      },
      {
        path: 'voting',
        component: () => import('@/pages/workspace/[page]/voting.vue'),
        name: 'workspace-voting',
      },
      {
        path: 'analytics',
        component: () => import('@/pages/workspace/[page]/analytics.vue'),
        name: 'workspace-analytics',
      },
      {
        path: 'dates',
        component: () => import('@/pages/workspace/[page]/dates.vue'),
        name: 'dates',
      },
    ],
  },
  {
    path: '/invitation/:id',
    meta: {
      layout: Default,
      title: 'Приглашение',
      guest: true,
    },
    name: 'invitation-index',
    component: () => import('@/pages/invitation.vue'),
  },
]

export default function () {
  const router = createRouter({
    history: isBrowser ? createWebHistory('/') : createMemoryHistory('/'),
    routes: [
      ...routes,
      {
        path: '/:pathMatch(.*)*',
        component: () => import('@/pages/404.vue'),
        name: '404',
        meta: {
          layout: Default,
        },
      },
    ],
  })

  const middleware = import.meta.glob('@/middleware/*.js')

  router.beforeResolve(async () => {
    if (useAuthStore().token && !useAuthStore().user) {
      await useAuthStore().fetchUser()
    }
  })

  for (const path in middleware) {
    middleware[path]().then((m) => {
      router.beforeResolve((t, f) => m.default(t, f))
    })
  }

  return router
}
