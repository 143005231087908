import { useLocalStorage } from '@vueuse/core'
import { defineStore } from 'pinia'
import { inject, nextTick, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { toast } from 'vue-sonner'

import useHttp from '@/core/http.js'
import { ziggyRoute } from '@/utils/ziggy-route.js'

export const useTenancyStore = defineStore('team', () => {
  const teamId = useLocalStorage('education-team', null)
  const team = ref(null)
  const router = useRouter()
  const teams = ref([])

  const loading = ref({
    tenants: false,
  })

  let tenancyAbortController = null

  watch(
    () => teamId.value,
    (v, o) => {
      if (teamId.value) {
        nextTick(() => fetchTenant(o !== undefined))
      } else {
        team.value = null
        if (o !== undefined) {
          router.push('/workspace')
        }
      }
    },
    {
      immediate: true,
    },
  )

  async function setTenant(id) {
    teamId.value = id

    await fetchTenant()
  }

  function leaveTenant() {
    team.value = null
    teamId.value = null
  }

  async function fetchTenants(force = false) {
    loading.value.tenants = true

    if (!force && teams.value.length > 0) {
      loading.value.tenants = false
      return {
        resources: teams.value,
      }
    }

    const response = await useHttp(ziggyRoute('restricted.competitions.index'))

    teams.value = response.resources
    loading.value.tenants = false

    return response
  }

  async function searchTenants(query = null) {
    loading.value.tenants = true

    if (tenancyAbortController) {
      tenancyAbortController.abort()
    }

    tenancyAbortController = new AbortController()

    const response = await useHttp(
      ziggyRoute('restricted.competitions.index'),
      {
        signal: tenancyAbortController.signal,
        params: {
          query,
        },
      },
    )

    loading.value.tenants = false

    return response
  }

  function fetchTenant(force = false) {
    loading.value.tenants = true
    useHttp(ziggyRoute('restricted.competitions.current'))
      .then(({ resource }) => {
        team.value = resource
        if (force) {
          router.push('/workspace')
        }
      })
      .catch((err) => {
        teamId.value = null

        if (err.statusCode === 404) {
          toast.error('Данный конкурс больше не существует')

          fetchTenants()
        }
      })
      .finally(() => (loading.value.tenants = false))
  }

  return {
    teamId,
    team,
    teams,
    loading,
    fetchTenants,
    fetchTenant,
    searchTenants,
    setTenant,
    leaveTenant,
  }
})
