<script setup>
import { watchOnce } from '@vueuse/core'
import { markRaw, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { useWorkspaceStore } from '@/stores/workspace.js'

import {
  IconCalendar,
  IconChartArea,
  IconCircle,
  IconCircleX,
  IconFolder,
  IconListDetails,
  IconListNumbers,
  IconSettings,
  IconStars,
  IconStarsFilled,
  IconUsers,
} from '@tabler/icons-vue'

import Combobox from '@/components/glass/combobox'
import LoadingView from '@/components/ui/LoadingView/LoadingView.js'
import useHttp from '@/core/http.js'
import { ziggyRoute } from '@/utils/ziggy-route.js'

const route = useRoute()
const router = useRouter()

const store = useWorkspaceStore()
const current = ref([])

watchOnce(
  () => store.resource,
  (value, oldValue, onCleanup) => {
    if (value) {
      current.value = [{ ...value }]
    }
  },
)

function changeWorkspace(value) {
  if (value.length) {
    store.fetchData(value[0].id).then(() => {
      router.replace(`/workspace/${value[0].id}/${store.actual}`)
    })
  }
}

async function searchTeams(search) {
  return await useHttp(ziggyRoute('restricted.competitions.index'), {
    method: 'GET',
    query: {
      query: search,
    },
  }).then(({ resources }) => {
    return resources
  })
}

const icons = {
  general: markRaw(IconSettings),
  'works-form': markRaw(IconListDetails),
  'reviews-form': markRaw(IconListNumbers),
  dates: markRaw(IconCalendar),
  rules: markRaw(IconCircleX),
  'competition-works': markRaw(IconFolder),
  moderation: markRaw(IconStars),
  reviews: markRaw(IconStarsFilled),
  analytics: markRaw(IconChartArea),
  users: markRaw(IconUsers),
}
</script>

<template>
  <div class="px-6 py-4 bg-background h-full overflow-y-auto">
    <LoadingView :is-loading="store.isPending">
      <Combobox
        class="w-full mb-4 mt-2"
        v-model="current"
        :search="searchTeams"
        :deselect="false"
        autosize
        @update:model-value="changeWorkspace"
      >
        <template #default="{ value }">
          <div
            class="relative bg-background border transition-colors pt-2 rounded pl-4 pr-4 pb-2 truncate flex flex-wrap gap-2"
          >
            <span v-for="(item, i) in value" class="truncate">{{
              item.title
            }}</span>
          </div>
        </template>
      </Combobox>
      <div class="flex flex-col" v-for="(group, i) in store.navigation">
        <hr v-if="i !== 0" class="my-2" />
        <RouterLink
          v-for="resource in group"
          :key="resource.slug"
          class="py-2 hover:text-primary transition-colors text-sm font-medium text-foreground/80 flex items-center"
          :exact-active-class="'text-primary'"
          disabled
          :class="{
            'opacity-50 pointer-events-none': resource.disabled,
          }"
          :to="`/workspace/${$route.params.team}/${resource.slug}`"
        >
          <component
            :is="icons[resource.slug] ?? IconCircle"
            class="size-5 mr-3"
          />
          <span v-text="resource.title" />
        </RouterLink>
      </div>
    </LoadingView>
  </div>
</template>
