<template>
  <component
    :is="settings?.logo_external_link ? 'a' : 'router-link'"
    :href="settings?.logo_external_link"
    class="max-w-[144px] aspect-[4/1] cursor-pointer"
    to="/"
  >
    <img
      v-if="logo"
      :src="logo"
      alt="Application logo"
      class="size-full object-contain object-left"
    />
    <div v-else class="whitespace-nowrap text-lg font-bold">
      {{ settings?.name }}
    </div>
  </component>
</template>

<script setup>
import { computed } from 'vue'

import useTheme from '@/core/theme.js'

const isDark = useTheme()
const settings = globalThis.SETTINGS

const logo = computed(() => {
  if (isDark.value && settings?.logo_dark?.length) {
    return settings.logo_dark[0].path
  }

  if (settings?.logo?.length) {
    return settings.logo[0].path
  }

  return null
})
</script>
